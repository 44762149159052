import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-10 mr-5 ml-5 text-right" }
const _hoisted_2 = { class: "text-h5" }
const _hoisted_3 = { class: "mr-5 ml-5 v-theme--light" }
const _hoisted_4 = { class: "text-center ma-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.dialog,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialog) = $event)),
        "max-width": "500px"
      }, {
        activator: _withCtx(({ props }) => [
          _createVNode(_component_v_btn, _mergeProps({
            color: "primary",
            dark: "",
            class: "mb-2"
          }, props), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("newItem")), 1)
            ]),
            _: 2
          }, 1040)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("newTemplate")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_file_input, {
                                label: _ctx.$t('chooseFile'),
                                accept: ".zip",
                                onChange: _ctx.onFileChange
                              }, null, 8, ["label", "onChange"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue-darken-1",
                    variant: "text",
                    onClick: _ctx.close
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("cancel")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    color: "blue-darken-1",
                    variant: "text",
                    onClick: _ctx.save
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("save")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.dialogDelete,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogDelete) = $event)),
        "max-width": "500px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "text-h5" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("confirmMessage")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue-darken-1",
                    variant: "text",
                    onClick: _ctx.closeDelete
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("cancel")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    color: "blue-darken-1",
                    variant: "text",
                    onClick: _ctx.deleteItemConfirm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("ok")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_spacer)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_DataTable, {
        value: _ctx.templates,
        paginator: true,
        rows: _ctx.rowsPerPage,
        paginatorPosition: 'bottom',
        onSort: _ctx.onSort,
        sortField: _ctx.sortField,
        sortOrder: _ctx.sortOrder,
        globalFilter: _ctx.globalFilter,
        stripedRows: "",
        emptyMessage: _ctx.$t('noRecords'),
        class: "elevation-1"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "id",
            header: _ctx.$t('idHeader'),
            sortable: true,
            filter: true,
            filterPlaceholder: "Search Id"
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "version",
            header: _ctx.$t('version'),
            sortable: false
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "title",
            header: _ctx.$t('title'),
            sortable: false
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "state",
            header: _ctx.$t('state'),
            sortable: true
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "createdTimestamp",
            header: _ctx.$t('createdTimestamp'),
            sortable: false
          }, {
            body: _withCtx((rowData) => [
              _createTextVNode(_toDisplayString(_ctx.formatDate(rowData.data.createdTimestamp)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "creator",
            header: _ctx.$t('creator'),
            sortable: false
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "identifier",
            header: _ctx.$t('identifier'),
            sortable: false
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "inForceFrom",
            header: _ctx.$t('inForceFrom'),
            sortable: false
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "language",
            header: _ctx.$t('language'),
            sortable: false
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "publisher",
            header: _ctx.$t('publisher'),
            sortable: false
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "validDateFrom",
            header: _ctx.$t('validDateFrom'),
            sortable: false
          }, {
            body: _withCtx((rowData) => [
              _createTextVNode(_toDisplayString(_ctx.formatDate(rowData.data.validDateFrom)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "validDateTo",
            header: _ctx.$t('validDateTo'),
            sortable: false
          }, {
            body: _withCtx((rowData) => [
              _createTextVNode(_toDisplayString(_ctx.formatDate(rowData.data.validDateTo)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "updatedTimestamp",
            header: _ctx.$t('updatedTimestamp'),
            sortable: false
          }, {
            body: _withCtx((rowData) => [
              _createTextVNode(_toDisplayString(_ctx.formatDate(rowData.data.updatedTimestamp)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('actions'),
            headerStyle: { minWidth: '250px', 'text-align': 'center' }
          }, {
            body: _withCtx((rowData) => [
              _createVNode(_component_Button, {
                icon: "pi pi-plus-circle",
                severity: "secondary",
                class: "p-button-rounded mr-1",
                onClick: ($event: any) => (_ctx.createNewSubmission(rowData.data.id))
              }, null, 8, ["onClick"]),
              _createVNode(_component_Button, {
                icon: "pi pi-file-pdf",
                severity: "primary",
                class: "p-button-rounded mr-1",
                onClick: ($event: any) => (_ctx.generatePdf(rowData.data.id))
              }, null, 8, ["onClick"]),
              _createVNode(_component_Button, {
                icon: "pi pi-download",
                severity: "success",
                class: "p-button-rounded mr-1",
                onClick: ($event: any) => (_ctx.downloadTemplate(rowData.data.id))
              }, null, 8, ["onClick"]),
              _createVNode(_component_Button, {
                icon: "pi pi-trash",
                class: "p-button-danger p-button-rounded",
                onClick: ($event: any) => (_ctx.deleteRowFunction(rowData.data.id))
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["value", "rows", "onSort", "sortField", "sortOrder", "globalFilter", "emptyMessage"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.snackbar,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.snackbar) = $event)),
        timeout: _ctx.timeout
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_btn, {
            color: "red",
            variant: "text",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.snackbar = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Close ")
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.text) + " ", 1)
        ]),
        _: 1
      }, 8, ["modelValue", "timeout"])
    ])
  ], 64))
}