<template>
  <div class="mt-10 mr-5 ml-5 text-right">
    <v-dialog v-model="dialog" max-width="500px">
      <template v-slot:activator="{ props }">
        <v-btn color="primary" dark class="mb-2" v-bind="props">
          {{ $t("newItem") }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{ $t("newTemplate") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  :label="$t('chooseFile')"
                  accept=".zip"
                  @change="onFileChange"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="close">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="save">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("confirmMessage") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
            >{{ $t("cancel") }}
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm"
            >{{ $t("ok") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div class="mr-5 ml-5 v-theme--light">
    <DataTable
      :value="templates"
      :paginator="true"
      :rows="rowsPerPage"
      :paginatorPosition="'bottom'"
      @sort="onSort"
      :sortField="sortField"
      :sortOrder="sortOrder"
      :globalFilter="globalFilter"
      stripedRows
      :emptyMessage="$t('noRecords')"
      class="elevation-1"
    >
      <Column
        field="id"
        :header="$t('idHeader')"
        :sortable="true"
        :filter="true"
        filterPlaceholder="Search Id"
      ></Column>
      <Column
        field="version"
        :header="$t('version')"
        :sortable="false"
      ></Column>
      <Column field="title" :header="$t('title')" :sortable="false"></Column>
      <Column field="state" :header="$t('state')" :sortable="true"></Column>
      <Column
        field="createdTimestamp"
        :header="$t('createdTimestamp')"
        :sortable="false"
      >
        <template #body="rowData">
          {{ formatDate(rowData.data.createdTimestamp) }}
        </template>
      </Column>
      <Column
        field="creator"
        :header="$t('creator')"
        :sortable="false"
      ></Column>
      <!--<Column
        field="description"
        :header="$t('description')"
        :sortable="false"
      ></Column>-->
      <Column
        field="identifier"
        :header="$t('identifier')"
        :sortable="false"
      ></Column>
      <Column
        field="inForceFrom"
        :header="$t('inForceFrom')"
        :sortable="false"
      ></Column>
      <Column
        field="language"
        :header="$t('language')"
        :sortable="false"
      ></Column>
      <Column
        field="publisher"
        :header="$t('publisher')"
        :sortable="false"
      ></Column>
      <Column
        field="validDateFrom"
        :header="$t('validDateFrom')"
        :sortable="false"
      >
        <template #body="rowData">
          {{ formatDate(rowData.data.validDateFrom) }}
        </template>
      </Column>
      <Column field="validDateTo" :header="$t('validDateTo')" :sortable="false">
        <template #body="rowData">
          {{ formatDate(rowData.data.validDateTo) }}
        </template>
      </Column>
      <Column
        field="updatedTimestamp"
        :header="$t('updatedTimestamp')"
        :sortable="false"
      >
        <template #body="rowData">
          {{ formatDate(rowData.data.updatedTimestamp) }}
        </template>
      </Column>
      <Column
        :header="$t('actions')"
        :headerStyle="{ minWidth: '250px', 'text-align': 'center' }"
      >
        <template #body="rowData">
          <Button
            icon="pi pi-plus-circle"
            severity="secondary"
            class="p-button-rounded mr-1"
            @click="createNewSubmission(rowData.data.id)"
          ></Button>
          <Button
            icon="pi pi-file-pdf"
            severity="primary"
            class="p-button-rounded mr-1"
            @click="generatePdf(rowData.data.id)"
          ></Button>
          <Button
            icon="pi pi-download"
            severity="success"
            class="p-button-rounded mr-1"
            @click="downloadTemplate(rowData.data.id)"
          ></Button>
          <Button
            icon="pi pi-trash"
            class="p-button-danger p-button-rounded"
            @click="deleteRowFunction(rowData.data.id)"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </div>
  <div class="text-center ma-2">
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:actions>
        <v-btn color="red" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DataTable, { DataTableSortEvent } from "primevue/datatable";
import Column from "primevue/column";
import api from "../api/api";
import dayjs from "dayjs";
import Button from "primevue/button";

export default defineComponent({
  name: "EForms",
  components: {
    DataTable,
    Column,
    Button,
  },

  data: () => ({
    sortBy: [{ key: "id", order: "asc" }],
    templates: [],
    search: "",
    currentFile: undefined as File | undefined,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      id: 0,
      version: "",
      createdTimestamp: new Date(),
      creator: "",
      description: "",
      identifier: "",
      inForceFrom: new Date(),
      language: "",
      publisher: "",
      title: "",
      updatedTimestamp: new Date(),
      validDateFrom: new Date(),
    },
    defaultItem: {
      id: 0,
      version: "",
      createdTimestamp: new Date(),
      creator: "",
      description: "",
      identifier: "",
      inForceFrom: new Date(),
      language: "",
      publisher: "",
      title: "",
      updatedTimestamp: new Date(),
      validDateFrom: new Date(),
    },
    snackbar: false,
    text: ``,
    timeout: 5000,
    sortField: "id",
    sortOrder: 1,
    rowsPerPage: 10,
    globalFilter: "",
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const response = await api.endpoints.getTemplates();
      this.templates = response.data;
    },

    downloadTemplate(id: number) {
      let downloadUrl = api.BASE_URL + "/templates/get-template-content/" + id;
      window.open(downloadUrl, "_blank");
    },

    deleteItem(item: never) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        await api.endpoints.deleteTemplate(this.editedItem.id);
      } catch (error: any) {
        this.snackbar = true;
        this.text = error.response.data;
      }
      await this.fetchData();
      this.closeDelete();
    },

    async generatePdf(id: number) {
      let downloadUrl = api.BASE_URL + "/templates/generatePdf/" + id;
      window.open(downloadUrl, "_blank");
    },

    async createNewSubmission(id: number) {
      const response = await api.endpoints.getNewSubmission(id);
      this.$router.push("/submission/" + response.data);
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      const formData = new FormData();
      if (!this.currentFile) {
        return;
      }
      formData.append("file", this.currentFile);
      try {
        await api.endpoints.uploadTemplate(formData);
      } catch (error: any) {
        this.snackbar = true;
        this.text = error.message;
      }
      await this.fetchData();
      this.close();
    },

    onFileChange(event: Event) {
      this.currentFile = (event.target as HTMLInputElement).files?.[0];
    },
    formatDate(date: string) {
      if (date === null || date === undefined) return "";
      return dayjs(date).format("DD.MM.YYYY");
    },
    onSort(event: DataTableSortEvent) {
      const { sortField, sortOrder } = event;
      this.sortField = sortField as string;
      this.sortOrder = sortOrder as number;
      this.fetchData();
    },
    deleteRowFunction(id: number) {
      this.deleteItem(this.templates[id]);
    },
  },
});
</script>

<style lang="scss" scoped></style>
